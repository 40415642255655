

.tn-prod-season-header__title{
    margin-top: 40px;
    font-family: adelle, serif;
    font-weight: 700;
    font-style: normal;
}


.tn-zone-selector__option{
    .tn-radio-button-list__button{
        @include btn_alt_style;
        border-left: 1px solid #e2231a;
    }
}
.tn-radio-button-list__input:checked + .tn-radio-button-list__button{
    background: $the_red !important;
}


