:root {
    --text-color: #5E5E5E;
    --heading-color: #181C24;
    --main-red: #E2231A;
}

$text_color : #070808;
$heading_color : #181C24;

$the_red: #e2231a;
$red: $the_red;
$dark_blue : #181C24;
$beige : #F5F4E6;

$btn_hover: #010101;
$btn_hover_alt: #c01e16;

@import "general.scss";
@import "event-page.scss";
@import "header.scss";
@import "footer.scss";



