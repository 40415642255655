

/* -------------------- Header --------------------*/

.primary-menu-wrapper.order-md-last{
    display: none !important;
}
.clear-both.hours-display{
    display: none;
}
.nav > li > a:focus, .nav > li > a:hover{
    background: transparent;
}
#navbar-top.navbar-dark .nav-link:hover,
#navbar-top.navbar-dark .nav-link:focus{

    
}

#navbar-top.navbar-dark .nav-link{
    @include btn_dark;
}

#navbar-top.navbar-dark .return-to-website-menu{
    .nav-link{
        @include btn_gray;
        text-transform: none;
        font-size: 14px;
    }
}

#navbar-top.navbar-dark .donate-menu{
    .nav-link{
        @include btn;
        padding: 0.6875rem 1.125rem;
        font-size: 13px !important;
        &:hover{
            background-color: #C62221;
            color: rgba(255,255,255,.75);
        }
    }
}

#navbar-main .nav-item a{
    @media (max-width: 74.9988rem){
        font-size: 13px;
    }
}