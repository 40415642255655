
/* -------------------- Footer --------------------*/

.site-footer{
    padding: 0;
}

.copyright{
    margin-bottom: 25px;
}

.site-footer__networking{
    display: block;
}
.site-footer__top.row{
    display: block;
}
.site-footer__top {
    padding-top: 0;
}

.col-sm-12.col-md-6.wordfly-block {
    display: none;
}
.col-sm-12.col-md-6.social-media {
    max-width: 100% !important;
}

.site-footer .site-footer-faux {
    -webkit-transform: translateY(-155px);
    -o-transform: translateY(-155px);
    transform: none;
    background-position: 0 -178px;
}

.col-sm-12.col-md-4.region.region-footer-first{
    max-width: 100%;
}

.clearfix.text-formatted.field.field--name-body.field--type-text-with-summary.field--label-hidden.field__item {
    column-count: 3;
}
.col-sm-12.col-md-8.region.region-footer-second {
    display: none;
}

.site-footer .site-footer-faux{
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    padding: 0;
    bottom: 0;
}
.copyright{
    z-index: 99;
    position: relative;
}

@media (max-width: 768px){
    .clearfix.text-formatted.field.field--name-body.field--type-text-with-summary.field--label-hidden.field__item{
        column-count: 1;
    }
}