html {
    font-size: 16px;
    color: var(--text-color);
}

h1,h2,h3,h4,h5,h6{
    color: var(--heading-color);
}
h2{
    font-size: 30px;
}


/* -------------------- TNEW overides --------------------*/

@mixin btn_alt_style{
    font-size: 15px;
    background: #F5F4E6 !important;
    border-right: 1px solid #dedede;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    border-left: 4px solid #e2231a;
    color: #000;
    &:hover{
        // text-decoration: underline;
        color: white;
        border-left: 4px solid $btn_hover_alt;
        background: $btn_hover_alt !important;
    }
}

.btn_alt_style{
    @include btn_alt_style;
}

@mixin btn_dark{
    background-color: #070808;
    color: white;
}

@mixin btn_gray{
    background-color: #eaebeb;
    color: #070808;
    &:hover{
        background-color: darken(#eaebeb,10%);
    }
}


/*  General  */

.container{
    p{
    }
}

p{
    color: $text_color;
    font-size: 1rem;
    line-height: 1.625rem;
}

ul{
    color: $text_color;
}

caption{
    caption-side: top;
}

@mixin link{
    color: var(--main-red);
    &:hover {
        color: $btn_hover;
        text-decoration: underline;
    }
    &:focus, &:hover {
        color: $btn_hover;
    }
}

a{
    @include link;
}

a.arrow{
    font-weight: bold;
    font-size: 1.125rem;
    color: var(--main-red);
}

.titles{
    font-family: adelle-sans,sans-serif;
    font-weight: 700;
    font-style: normal;
}

.btn-block{
    width: auto;
}

@media (max-width: 61.99875rem) {
    #header{
        .collapse{
            display: none !important;
        }
        .collapse.in{
            display: block !important;
        }
    }
}



.branding-wrapper{
    margin-right: auto;
}

.fade:not(.show) {
    opacity: 1;
}



/*  Elements  */

.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover{
    background: $btn_hover;
    border-color: $btn_hover;
}

@mixin btn{
    background: #E2231A linear-gradient(89deg,#C62221 0%,#E2231A 100%) 0% 0% no-repeat padding-box;
    padding: 0.875rem 1.125rem;
    color: white;
    font-size: 1rem;
    border-radius: 0;
    &:hover{
        background: $btn_hover;
        border-color: $btn_hover;
    }
}

.btn{
    background: #E2231A linear-gradient(89deg,#C62221 0%,#E2231A 100%) 0% 0% no-repeat padding-box;
    padding: 0.875rem 1.125rem;
    color: white;
    font-size: 1rem;
    border-radius: 0;
    &:hover{
        background: $btn_hover;
        border-color: $btn_hover;
    }
}

a.btn{
    @include btn;
}

.btn:hover{
    color: white;
    background-color: $btn_hover;
}

.btn.disabled, .btn:disabled{
    opacity: 1;
}

@mixin arrow_link{
    position: relative;
    display: inline-block;
    padding-right: 1.375rem;
    color: #E2231A;
    font-weight: bold;
    font-size: 1.125rem;

    &:after {
        content: " ";
        background-image: url(icons/icon-button-arrow-right.svg);
        display: block;
        position: absolute;
        width: 15px;
        height: 11px;
        right: 0;
        top: 7px;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
    }
    &:hover {
        color: #1B2D48;
        text-decoration: underline;
        &:after{
            transform: translateX(5px);
        }
    }

}
.arrow_link{
    @include arrow_link;
}

@mixin arrow_link_1{
    @include arrow_link;
    font-size: 1rem;
}



.tn-btn-datepicker__btn-period-prev-next--btn-prev,
.tn-btn-datepicker__btn-period-prev-next--btn-next{
    border-radius: 0;
    background: #181C24;
    border-color: #181C24;
    &:hover,
    &:focus,
    &:active{
        background: #181C24 !important;
        border-color: #181C24 !important;
        border-color: #181C24 !important;
    }
    &.disabled,
    &:disabled{
        background: lighten(#181C24,10%) !important;
        border-color: lighten(#181C24,10%) !important;
    }
}

.btn.btn-lg.btn-primary.tn-btn-datepicker__btn-period-prev-next.tn-btn-datepicker__btn-period-prev-next--btn-prev,
.btn.btn-lg.btn-primary.tn-btn-datepicker__btn-period-prev-next.tn-btn-datepicker__btn-period-prev-next--btn-next{
    border-radius: 0;
}

.btn.btn-default.btn-lg.tn-btn-datepicker__btn{
    background: #F5F4E6;
}
#block-gtranslate {
    display: none;
}
.navbar-brand{
    height: auto !important;
}

.btn-primary {
    font-size: 18px;
}


#page select {
    width: 100%;
    max-width: 240px;
    height: 42px;
    // border: 1px solid #ffffff;
    border: 1px solid #ced4da;
    background: #F5F4E6;
    border-radius: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    background-image: url("data:image/svg+xml;utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201000%201805.18%22%3E%3Cpath%20fill%3D%22%23595d64%22%20d%3D%22M461.6%2C643.4L10.9%2C79.9C-14.9%2C47.7%2C8%2C0%2C49.3%2C0h901.5c41.2%2C0%2C64.1%2C47.7%2C38.4%2C79.9L538.4%2C643.4%09C518.7%2C668%2C481.3%2C668%2C461.6%2C643.4z%22%20transform%3D%22rotate%28180%20500%20902.59%29%20translate%280%201143.28%29%22%3E%3C%2Fpath%3E%3Cpath%20fill%3D%22%23595d64%22%20d%3D%22M461.6%2C643.4L10.9%2C79.9C-14.9%2C47.7%2C8%2C0%2C49.3%2C0h901.5c41.2%2C0%2C64.1%2C47.7%2C38.4%2C79.9L538.4%2C643.4%09C518.7%2C668%2C481.3%2C668%2C461.6%2C643.4z%22%20transform%3D%22translate%280%201143.28%29%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
    background-color: #ffffff;
    background-size: 10% 40%;
    background-position: right 7px top 50%;
    background-repeat: no-repeat;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}


#tn-apply-promo{
    flex-direction: row;
    display: flex;
    #tn-subnav-promo-button{

    }
    #tn-subnav-promo-code{
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        padding-right: 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        border-radius: 0;

    }
}

#tn-subnav-promo-button{
    @include btn;
    text-transform: none;
    text-transform: capitalize;
    text-decoration: none;
}



.tn-heading-sub-text p{
    background: #F5F4E6 !important;
    font-size: 16px !important;
    padding: 22px 15px !important;
    max-width: 100% !important;;
    a{
        // @include arrow_link;
        display: block;
        // max-width: 122px;
        font-size: 1rem;
        font-weight: bold;
    }
}

/* ---------------------Login bar -------------*/


.tn-login-link{
    .tn-link{
        @include btn;
    }
}
.tn-cart-link{
    .tn-link{
        @include btn;
    }
}

/* ---------------------Title Header -------------*/

#title_header{
    margin-bottom: 0;
}

section.tn-header-component div:not(.tn-heading-sub-text), section.tn-header-component h1{
    margin-top: 0.5em;
}
/* ---------------------General forms -------------*/

.form-control {
    border-radius: 0;
    box-shadow: none;
}

.has-error .form-control {

}

/* -------------------- Pages --------------------*/

#main {
    padding-bottom: 120px;
}


.tn-header-component .tn-heading-sub-text p{
    font-family: adelle-sans, sans-serif;
    font-size: 1em;
}


/* -------------------- Event Listing Page --------------------*/

.tn-events-listing-page .tn-event-listing__primary-view {
    background-color: transparent;
}

.tn-events-listing-page .tn-events-calendar__table .tn-events-calendar__event {
    padding: 20px 5px 10px 6px;
    font-size: 15px;

    padding: 20px 5px 10px 6px;
    font-size: 15px;
    background: #F5F4E6 !important;
    border-right: 1px solid#dedede;
    border-top: 1px solid#dedede;
    border-bottom: 1px solid#dedede;
    border-left: 4px solid $the_red;
    color: black;
    &:hover{
        // text-decoration: underline;
        color: white;
        border-left: 4px solid $btn_hover_alt;
        background: $btn_hover_alt !important;
    }
}
.tn-events-listing-page .tn-events-calendar__table .tn-events-calendar__event-status {
    margin: 12px 0px 0 0;
    padding: 5px;
}

/* TABS */
.tn-events-listing-page .tn-event-listing-mode-tab-nav__list {
    border-bottom: 0;
}

.tn-events-listing-page .tn-event-listing-mode-tab-nav__list-item--secondary{
    background: none;
}

.tn-events-listing-page .tn-event-listing-mode-tab-nav__list-item{
    background: #F5F4E6;
    margin: 0;
    // border: 2px solid #181C24;
    border: 0;
    color: #181C24;
    margin-left: 5px;
    &:hover{
        background: #181C24;
        color: white;
    }
}

.tn-events-listing-page .tn-event-listing-mode-tab-nav__list-item.active{
    background: #181C24;
    margin-bottom: 0;
    // border: 2px solid #181C24;
    color: white;
}


/* Calendar */

.tn-event-listing__primary-views-container{
    // margin-top: -50px;
}

.tn-btn-datepicker__btn-container{
    .tn-btn-datepicker__date-display{
        color: var(--heading-color);
    }
    .day.selected{
        background: $dark_blue;
    }
}

.tn-events-listing-page{
    .tn-datepicker td.day[aria-current="date"] span{
        background: $dark_blue;
    }
    .tn-datepicker{
        border-radius: 0;
    }
}

.tn-datepicker .btn{
    background: transparent;
}

.tn-events-listing-page .tn-events-calendar__table th{
    background: #181C24;
}

.glyphicon.glyphicon-calendar{
    color:#181C24;
    &:before{
        color:#181C24;
    }
}

// Mobile calendar

@media (max-width: 768px){
    .tn-events-calendar__event-time{
        position: relative !important;
        left: 0px !important;
        padding-bottom: 10px !important;
        top: 0 !important;

    }
}

// Lsit View

.tn-event-listing__primary-views-container{
    li.tn-prod-list-item{
        background: $beige;
    }
}

.tn-events-listing-page{
    .tn-prod-list-item__perf-anchor{
        padding-right: 60px;
    }
}

.tn-events-list-view__controls{
    button.tn-events-list-view__btn-reset-dates{
        padding: 10px 16px;
        margin-left: 20px;
        @include btn;
        &:hover{
            background: $btn_hover;
        }
    }
}



#tn-events-list-view{
    .tn-prod-list-item{
        border: 1px solid #dedede;
        .tn-prod-list-item__property{
            h3{
                font-size: 1.2rem;
            }
        }
    }
}

.tn-prod-list-item__property{

}


// Date picker

.tn-datepicker{
    border: 1px solid rgba(0,0,0,.11);
    box-shadow: 0 5px 15px rgba(0,0,0,.08);
}
.tn-datepicker td.day[aria-current="date"] span{
    background-color: #181C24;
    border-color: #181C24;
}
table.calendar{
    td{
        width: 54px;
        span{
            height: 36px;
            padding-top: 3px;
        }
    }
}



.tn-events-calendar__table{
    caption{
        margin-top: 20px;
    }
}
#page{
    .tn-event-listing-view__results-heading{
        color: $heading_color
    }
}


/* -------------------- Event Detail --------------------*/

.tn-events-detail-page{
    .tn-prod-season-header__title-and-image-container{
        display: block;
    }
    .tn-prod-season-header__image-wrapper{
        display: block;
    }
    .tn-prod-season-header__image-wrapper{
        width: 100%;
        // max-height: 350px;
        // overflow: hidden;
        .tn-prod-season-header__image{
            // margin-top: -10%;
        }
    }
    .tn-prod-season-header__description-container{
        background: transparent;
        padding: 20px 10px 0;
    }
    #tn-detail-production-text-content{
        display: block;
    }
    .tn-detail-production-text-toggle-link{
        display: none;
    }
    .tn-additional-events__instructions{
        background: white;
        padding: 20px 20px 10px 15px;
        border-bottom: none;
    }
    .tn-event-detail__main-container{
        order: 1;
    }
    .tn-event-detail__additional-events-container{
        background: white;
        order: 2
    }
    .tn-additional-events__continue-shopping-link{
        @include arrow_link_1;
    }
}

.tn-radio-button-list__input:checked + .tn-radio-button-list__button{
    &:hover{
        background: $btn_hover;
        border-color: $btn_hover;
    }
}

#tn-additional-events-btn-submit{
    .glyphicon{
        top: -1px;
    }
}

#page .tn-events-detail-page select{
    height: 49px;
    max-width: 232px;
}


/* ------------------------ Payment Page ------------------------*/
.tn-payment-page {
    h2{
        font-size: 30px;
    }
    .tn-patron-billing-information,
    .tn-patron-shipping-information{
        min-width: 50%;
        .col-xs-12.col-sm-6{
            max-width: 100%;
        }
    }
    #PaymentDonation_Amount{
        border-radius: 0;
    }
}

#page{
    .tn-cart-item-details__list{
        background-color: $beige;
    }
    .input-group-addon{
        padding: 11px 12px;
        border-radius: 0;
        background-color: $beige;
    }
}
/* -------------------- Login Page Detail --------------------*/

.tn-account-login-page {

    #tn-login-form {
        fieldset{
            max-width: 90%;
        }
    }

    .tn-login-component{
        h2{
            margin-bottom: 20px;
            font-size: 30px;
        }
    }

    .tn-register-button{
        border: 1px solid $the_red;
    }

    #tn-account-login-forgot-login-link{
        color: var(--main-red);
    }

    .tn-register-button,
    #tn-login-button{
            max-width: 165px;
            padding: 10px;
    }

    .tn-create-heading{
        margin-bottom: 20px;
        font-size: 30px;
    }
}

// ---------------------------------

#tn-update-form{
    .btn.btn-default.tn-btn-grouped{
        background: none;
        border: none;
        @include link;
    }
}

#tn-update-button{
    @include btn;
}

// --------------------------------- Dashboar menu

ul.tn-area-nav__list{
    .tn-area-nav__list-item{
        font-weight: bold;
        a{
            font-weight: 400;
        }
    }

}
// --------------------------------- Upcoming Events


#page {
    .tn-upcoming-event__date-time,
    .tn-digital-content__event-date-time{
        color: #E2231A;
        text-transform: uppercase;
        font-size:16px;
    }
    .tn-upcoming-event__title{
        font-family: adelle-sans, sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1.625rem;
        color: $heading_color;
        margin-bottom: 10px;
        &:hover{
            color: $btn_hover_alt;
            text-decoration: underline;
        }
    }
    .tn-upcoming-event__status-message{
        margin-top: 15px;
    }
}

@media (max-width: 768px){
    .tn-subnav-component {
        flex-wrap: wrap;
        .tn-logout-link,
        .tn-login-link{
            .tn-link{
                .tn-text{
                    display: inline;
                }
            }
        }
        .tn-promo-box{
            flex-basis: 100%;
            margin-top: 20px;
        }

    }
}

.bg-danger {
    background-color: #fcc !important;
  }